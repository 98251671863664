<div class="offer-lc-container" id="offer-lc-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="form">
    <button (click)="location.back()" class="back">
      <img src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(90deg);">
      {{'backToGalley' | translate}}
    </button>
    <img src="{{selectedPaining.path}}"/>
    <div class="form-container" *ngIf="!isDataSent">

      <h1>{{'placeOffer' | translate}}</h1>
      <form #form="ngForm">

        <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerEmailPlaceholder' | translate}}" name="email" [(ngModel)]="email"
               class="email">

        <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerFullNamePlaceholder' | translate}}" name="name" [(ngModel)]="fullName">

        <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerPhonePlaceholder' | translate}}" name="phone" [(ngModel)]="phone">

        <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'otherMentionsPlaceHolder' | translate}}" name="email"
               [(ngModel)]="additionalText">

        <p><input [ngModelOptions]="{standalone: true}" type="checkbox" [(ngModel)]="termsConditions" style="width: fit-content;">{{'agreeWith' | translate}}<a
          [routerLink]="'/TermsConditions'">{{'termsAndConditions' | translate}}</a></p>

        <p><input [ngModelOptions]="{standalone: true}" type="checkbox" [(ngModel)]="agreeData"
                  style="width: fit-content;">{{'agreeWithDataProcessing' | translate}}</p>

        <div class="messages">
          <p *ngIf="message.length > 0" class="message">{{message}}</p>
          <p *ngIf="errorMessage.length > 0" class="error-message">{{errorMessage}}</p>
        </div>

        <button mat-raised-button class="simple-button" (click)="send(form)">{{'sendOffer' | translate}}</button>
      </form>

    </div>
    <div *ngIf="isDataSent">
      <p class="success">{{email}}</p>
      <p class="success">{{phone}}</p>
      <p class="success" *ngIf="additionalText">{{additionalText}}</p>
      <p class="success">{{'requestSentSuccessfully' | translate}}!</p>
    </div>
  </div>
</div>
