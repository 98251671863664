import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {GalleryComponent} from './gallery/gallery.component';
import {EventsComponent} from './events/events.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {PaintingDetailsComponent} from './painting-details/painting-details.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import {FullCalendarModule} from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component'; // a plugin
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {AboutComponent} from './about/about.component';
import {GamesComponent} from './games/games.component';
import {NftComponent} from './nft/nft.component';
import {ContactComponent} from './contact/contact.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { MarketComponent } from './market/market.component';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { OfferComponent } from './offer/offer.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { MyOffersComponent } from './my-offers/my-offers.component';
import { AddArtComponent } from './add-art/add-art.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { EditArtComponent } from './edit-art/edit-art.component';
import { PaintingPageComponent } from './painting-page/painting-page.component';
import { RegisterComponent } from './register/register.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PopupComponent } from './popup/popup.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { LoaderComponent } from './loader/loader.component';
import { EventDetailsPageComponent } from './event-details-page/event-details-page.component';
import { OfferLcComponent } from './offer-lc/offer-lc.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GalleryComponent,
    EventsComponent,
    PaintingDetailsComponent,
    PageNotFoundComponent,
    AboutComponent,
    GamesComponent,
    NftComponent,
    ContactComponent,
    MarketComponent,
    LoginComponent,
    OfferComponent,
    MyAccountComponent,
    MyOffersComponent,
    AddArtComponent,
    AccountSettingsComponent,
    EditArtComponent,
    PaintingPageComponent,
    RegisterComponent,
    SubscriptionComponent,
    TermsConditionsComponent,
    PopupComponent,
    CookiePolicyComponent,
    LoaderComponent,
    EventDetailsPageComponent,
    OfferLcComponent,
  ],
  imports: [
    RecaptchaV3Module,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatMenuModule,
    MatGridListModule,
    MatDividerModule,
    MatCardModule,
    MatDialogModule,
    DragDropModule,
    MatExpansionModule,
    FullCalendarModule, // register FullCalendar with you app
    HttpClientModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
