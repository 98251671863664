import {Component, HostListener, Inject, OnInit, OnDestroy} from '@angular/core';
import {PaintingCathegoryService} from '../service/paintingCathegory.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Paintings} from '../shared/Paintings';
import {DOCUMENT} from '@angular/common';
import {ConfigService} from '../config/config.service';
import {LatestPaintingService} from '../service/latestPainting.service';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from '../service/language.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {

  // tslint:disable-next-line:variable-name max-line-length
  constructor(@Inject(DOCUMENT) private document: Document,
              public dialog: MatDialog, public router: Router, private api: ConfigService,
              // tslint:disable-next-line:variable-name
              private translate: TranslateService, private _language: LanguageService) {
    this.checkLanguage();
  }

  isLoaderVisible = false;
  windowScrolled = false;
  paintingCathegory = 0;
  paintings: Paintings[] = [];
  // tslint:disable-next-line:variable-name
  is_painting_selected = false;
  // tslint:disable-next-line:variable-name
  selected_painting: Paintings = {
    id: 0,
    path: '',
    name: '',
    category: '',
    tags: '',
    availability: 0,
    price: '',
    dimension: '',
    description: '',
    // tslint:disable-next-line:variable-name
    created_at: null,
    // tslint:disable-next-line:variable-name
    updated_at: null
  };
  // tslint:disable-next-line:variable-name
  chosen_resp_category = 'galleryCanvasPaintings';
  // tslint:disable-next-line:variable-name
  is_painting_selected_notCanvas = false;
  isCanvas = false;
  inititalPaintings: Paintings[] = [];
  currentPage = 0;
  maxPages = 0;
  paintingsPerPage = 12;
  firstPaintingIndex = 0;

  resetPagination(): void{
    this.currentPage = 0;
    this.maxPages = 0;
    this.paintingsPerPage = 12;
    this.firstPaintingIndex = 0;
    this.initiatePages();
  }

  DisplayPaintings(): void {
    switch (this.paintingCathegory) {
      case 1: {
        this.ResetButtonColor();
        // @ts-ignore
        document.getElementById('canvas').style.background = 'rgb(65,13,182)';
        break;
      }
      case 2: {
        this.ResetButtonColor();
        // @ts-ignore
        document.getElementById('wall').style.background = '#5a1bbf';
        break;
      }
      case 3: {
        this.ResetButtonColor();
        // @ts-ignore
        document.getElementById('mug').style.background = 'rgb(0,80,194)';
        break;
      }
      case 4: {
        this.ResetButtonColor();
        // @ts-ignore
        document.getElementById('gift').style.background = '#05a872';
        break;
      }
      case 5: {
        this.ResetButtonColor();
        // @ts-ignore
        document.getElementById('menus').style.background = 'rgb(4, 125, 71)';
        break;
      }
      // case 7: {
      //   this.ResetButtonColor();
      //   // @ts-ignore
      //   document.getElementById('numbers').style.background = '#02a5ad';
      //   break;
      // }
      default: {
        this.ResetButtonColor();
        this.paintingCathegory = 0;
      }
    }
    window.scroll(0, 0);
  }

  ResetButtonColor(): void {
    // @ts-ignore
    document.getElementById('canvas').style.background = 'transparent'; // rgb(41,16,112)';
    // @ts-ignore
    document.getElementById('wall').style.background = 'transparent'; //  'rgb(35,6,99)';
    // @ts-ignore
    document.getElementById('mug').style.background = 'transparent'; //  'rgb(2,46,108)';
    // @ts-ignore
    document.getElementById('gift').style.background = 'transparent'; //  'rgb(10,102,70)';
    // @ts-ignore
    document.getElementById('menus').style.background = 'transparent'; //  'rgb(22,94,69)';
    // @ts-ignore
    // document.getElementById('numbers').style.background = 'transparent'; //  'rgb(22,94,69)';
  }

  ChangeMenuColors(color: any): void {
    if (document.getElementById('responsive_p')) {
      // @ts-ignore
      document.getElementById('responsive_p').style.backgroundColor = color;
    }
    if (document.getElementById('resp_canvas')) {
      // @ts-ignore
      document.getElementById('resp_canvas').style.backgroundColor = color;
    }
    if (document.getElementById('resp_wall')) {
      // @ts-ignore
      document.getElementById('resp_wall').style.backgroundColor = color;
    }
    if (document.getElementById('resp_mug')) {
      // @ts-ignore
      document.getElementById('resp_mug').style.backgroundColor = color;
    }
    if (document.getElementById('resp_gift')) {
      // @ts-ignore
      document.getElementById('resp_gift').style.backgroundColor = color;
    }
    if (document.getElementById('resp_menu')) {
      // @ts-ignore
      document.getElementById('resp_menu').style.backgroundColor = color;
    }
    // if (document.getElementById('resp_numbers')) {
    //   // @ts-ignore
    //   document.getElementById('resp_numbers').style.backgroundColor = color;
    // }
  }

  // tslint:disable-next-line:variable-name
  ResponsivePaintingCategory(chosen_category: number): void {
    switch (chosen_category) {
      case 1: {
        this.chosen_resp_category = 'galleryCanvasPaintings';
        this.ChangeMenuColors('rgb(65,13,182)');
        this.isCanvas = true;
        break;
      }
      case 2: {
        this.chosen_resp_category = 'galleryWallPaintings';
        this.ChangeMenuColors('#5a1bbf');
        this.isCanvas = false;
        break;
      }
      case 3: {
        this.chosen_resp_category = 'galleryMugPaintings';
        this.ChangeMenuColors('rgb(0,80,194)');
        this.isCanvas = false;
        break;
      }
      case 4: {
        this.chosen_resp_category = 'gallerySpecialGifts';
        this.ChangeMenuColors('#05a872');
        this.isCanvas = false;
        break;
      }
      case 5: {
        this.chosen_resp_category = 'galleryMenus';
        this.ChangeMenuColors('rgb(4, 125, 71)');
        this.isCanvas = false;
        break;
      }
      // case 7: {
      //   this.chosen_resp_category = 'galleryHouseNumbers';
      //   this.ChangeMenuColors('#02a5ad');
      //   this.isCanvas = false;
      //   break;
      // }
      }
    this.PaintingCategory(chosen_category);
  }

  // tslint:disable-next-line:variable-name
  PaintingCategory(chosen_category: number): void {
    localStorage.setItem('category', String(chosen_category));
    this.is_painting_selected = false;
    this.is_painting_selected_notCanvas = false;
    this.paintingCathegory = chosen_category;
    if (this.paintingCathegory === 1) {
      this.isCanvas = true;
    } else {
      this.isCanvas = false;
    }
    this.isLoaderVisible = true;
    if (chosen_category === 0) {
      chosen_category = 1;
    }
    this.api.GetPaintings(String(chosen_category)).subscribe(
      data => {
        // @ts-ignore
        this.paintings = data;
        this.inititalPaintings = this.paintings;
        this.resetPagination();
        this.isLoaderVisible = false;
      },
      error => {
        console.log('oops', error);
        this.isLoaderVisible = false;
      }
    );
    this.DisplayPaintings();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTop(): void {
    // tslint:disable-next-line:typedef
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  GetAdditionalData(): void {
    for (const i of this.paintings) {
      const x = i.description.split('>');
      i.description = x[0];
      // @ts-ignore
      i.created_at = x[1];
    }
  }

  VerifyLatestPainting(): void {
    const latestPainting = localStorage.getItem('latestPainting');
    if (latestPainting) {
      for (const i of this.paintings) {
        if (i.id.toString() === latestPainting) {
          this.is_painting_selected = true;
          this.selected_painting = i;
        }
      }
    }
  }

  checkLanguage(): void {
    this._language.current_lang.subscribe(lang => {
      // tslint:disable-next-line:triple-equals
      if (lang == 0 ) {
        this.translate.use('ro');
      } else {
        this.translate.use('en');
      }
    });
    this.getTranslatedTitles();
  }

  getTranslatedDescription(description: string): void {
    if (description.indexOf('|') !== -1){
      const descr = description.split('|');
      this._language.current_lang.subscribe(lang => {
        // tslint:disable-next-line:triple-equals
        if (lang == 0 ) {
          this.selected_painting.description = descr[0];
        } else {
          this.selected_painting.description = descr[1];
        }
      });
    }
    else {
      this.selected_painting.description = description;
    }
  }
  getTranslatedName(name: string): void {
    if (name.indexOf('|') !== -1){
      // tslint:disable-next-line:variable-name
      const name_array = name.split('|');
      this._language.current_lang.subscribe(lang => {
        // tslint:disable-next-line:triple-equals
        if (lang == 0 ) {
          this.selected_painting.name = name_array[0];
        } else {
          this.selected_painting.name = name_array[1];
        }
      });
    }
    else {
      this.selected_painting.name = name;
    }
  }

  getTranslatedTitles(): void {
    this._language.current_lang.subscribe(lang => {
      this.paintings.forEach(painting => {
        if (painting.name.indexOf('|') !== -1){
          const name = painting.name.split('|');
          // tslint:disable-next-line:triple-equals
          if (lang == 0){
            painting.name = name[0];
          } else {
            painting.name = name[1];
          }
        }
      });
    });
  }

  reindexPaintings(): void {
    this.is_painting_selected = false;
    const paintingsPerPage: Paintings[] = [];
    let ct = this.paintingsPerPage;

    for (let i = this.currentPage * this.paintingsPerPage; i <= this.paintings.length - 1; i++){
      if (ct > 0){
        paintingsPerPage.push(this.paintings[i]);
        ct--;
      }
    }
    this.paintings = paintingsPerPage;
    window.scroll(0, 0);
  }

  initiatePages(): void {
    this.paintings = this.inititalPaintings;
    this.maxPages = Math.round(this.paintings.length / this.paintingsPerPage + 0.5);
    this.firstPaintingIndex = this.paintings[0].id;
    const paintingsPerPage: Paintings[] = [];
    let ct = 0;
    this.paintings.forEach(painting => {
      if (painting.id <= this.firstPaintingIndex && ct < this.paintingsPerPage){
        paintingsPerPage.push(painting);
        ct++;
      }
    });
    this.paintings = paintingsPerPage;
  }

  nextPage(): void {
    if (this.currentPage < this.maxPages - 1 ) {
      this.paintings = this.inititalPaintings;
      this.currentPage++;
      this.reindexPaintings();
    }
  }

  previousPage(): void {
    if (this.currentPage > 0){
      this.currentPage--;
      this.paintings = this.inititalPaintings;
      this.reindexPaintings();
    }
  }

  ngOnDestroy(): void{
    localStorage.setItem('category', '1');
  }
  ngOnInit(): void {
    this.checkLanguage();
    this.getTranslatedTitles();
    // @ts-ignore
    // document.getElementById('responsive_p').style.backgroundColor = '#2e107d';
    const category = localStorage.getItem('category');
    this.paintingCathegory = (category === '0') ? 1 : Number(category);
    if (this.paintingCathegory === 1) {
      this.isCanvas = true;
    }
    this.ResponsivePaintingCategory(this.paintingCathegory);
    // this.PaintingCategory(this.paintingCathegory);
    this.VerifyLatestPainting();
  }
}
