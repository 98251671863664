<div class="offer-container" id="offer-container">
  <div *ngIf="isLoaderVisible">
    <app-loader></app-loader>
  </div>
  <div class="form">
    <button (click)="location.back()" class="back">
      <img src="assets/other/arrow-down-sign-to-navigate.svg" style="transform: rotate(90deg);">
      {{'backToGalley' | translate}}
    </button>
    <img src="{{paintingPath}}"/>
    <p class="last-offer-floor">{{priceDisplayed}}</p>
    <div  class="form-container">
      <div class="messages">
        <p *ngIf="message.length > 0" class="message">{{message}}</p>
        <p *ngIf="errorMessage.length > 0" class="error-message">{{errorMessage}}</p>
      </div>

      <div *ngIf="isFormVisible">
        <h1>{{'placeOffer' | translate}}</h1>

        <form #form="ngForm">
          <div *ngIf="!isUserLoggedIn">
            <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerEmailPlaceholder' | translate}}" name="email" [(ngModel)]="email" class="email">

            <p><a href="#" routerLink="/Login">{{'alreadyHaveAccountOrWantOne?' | translate}}</a></p>

            <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerFullNamePlaceholder' | translate}}" name="name" [(ngModel)]="fullName">

            <input [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'offerPhonePlaceholder' | translate}}" name="phone" [(ngModel)]="phone">
          </div>
          <div *ngIf="isUserLoggedIn">
            <p>{{'offerEmail' | translate}} {{email}}</p>
            <p>{{'offerFullName' | translate}} {{fullName}}</p>
            <p>{{'offerPhoneNumber' | translate}} {{phone}}</p>
          </div>

          <!--        <input type="text" placeholder="{{'allocatedBudgetPlaceHolder' | translate}}" name="offer" [(ngModel)]="offer">-->
          <input [ngModelOptions]="{standalone: true}" type="textarea" placeholder="{{'otherMentionsPlaceHolder' | translate}}" name="email" [(ngModel)]="additionalText">

          <p *ngIf="!isUserLoggedIn">
            <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="termsConditions" style="width: fit-content;">{{'agreeWith' | translate}}<a [routerLink]="'/TermsConditions'">{{'termsAndConditions' | translate}}</a></p>
          <p *ngIf="!isUserLoggedIn">
            <input type="checkbox" [ngModelOptions]="{standalone: true}" [(ngModel)]="agreeData" style="width: fit-content;">{{'agreeWithDataProcessing' | translate}}</p>

<!--          <button (click)="sendOffer()">{{'sendOffer' | translate}}</button>-->
          <button mat-raised-button (click)="send(form)">{{'sendOffer' | translate}}</button>
        </form>
      </div>
    </div>
  </div>
</div>
