<body class="popup-body" id="body">
  <div *ngIf="!isEvents"  class="body-404">
  <img src="assets/logo/simple_lupusor_white.svg" class="zoom-wolf">
  <div class="popup-container">
    <table>
      <tr>
        <td>
          GAME OVER <br><br>
          Wolf has got to you! <br>
          Your only choice now is to go home
        </td>
      </tr>
    </table>
    <button class="popup-button" (click)="goHome()">Go home</button>
  </div>
</div>
  <div *ngIf="isEvents" class="poster">
<!--    <img src="assets/exhibition2/castigator.jpg" class="poster" id="poster">-->
<!--    <img src="assets/exihibition1/mom.jpeg" class="responsive-poster" id="responsive-poster">-->
  </div>
  <div *ngIf="isEvents" class="responsive-poster">
  </div>
</body>
