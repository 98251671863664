import {Component, HostListener, OnInit} from '@angular/core';
import {PaintingCathegoryService} from './service/paintingCathegory.service';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from './service/language.service';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {NgForm} from '@angular/forms';
// tslint:disable-next-line:ban-types
// declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  constructor(private router: Router,
              // tslint:disable-next-line:variable-name
              private translate: TranslateService, private _language: LanguageService) {
    // translate.addLangs(['en', 'ro']);
    // translate.setDefaultLang('ro');
    this._language.current_lang.subscribe(lang => {
      if (lang === 0 ) {
        translate.use('ro');
      } else {
        translate.use('en');
      }
    });
  }
  title = 'LupulCreativ';
  waveNr = 200;
  isHomeLocation = false;
  langId = 0;
  isCookieVisible = true;

  // tslint:disable-next-line:typedef
  UpdatePaintingCathegory(cathegory: number){
    localStorage.setItem('category', String(cathegory));
    this.router.navigate(['Gallery']);
  }
  Open(link: string): void{
    window.open('https://www.' + link);
  }

  checkLocation(): void {
    const url = window.location.href;
    const location = url.substring(url.lastIndexOf('/') + 1);
    this.isHomeLocation = location === '';
  }

  changedHref(): void {
    this.checkLocation();
  }

  updateLanguage(): void {
    this._language.current_lang.subscribe(lang => {
      // tslint:disable-next-line:triple-equals
      if (lang == 0 ) {
        this.translate.use('ro');
      } else {
        this.translate.use('en');
      }
    });
  }

  async languageChange(event: any): Promise<void> {
    localStorage.setItem('lang', event.target.value.toString());
    this._language.update_lang(event.target.value);
    await this.updateLanguage();
    location.reload();
  }

  checkLanguage(): void {
    if (!localStorage.getItem('lang') || localStorage.getItem('lang') === '0'){
      this._language.update_lang(0);
      this.langId = 0;
    } else {
      this._language.update_lang(1);
      this.langId = 1;
    }
    this.updateLanguage();
  }
  test(): void {
    localStorage.setItem('category', '1');
    this.router.navigate(['Gallery']).then(r => console.log('ss'));
  }
  @HostListener('window:scroll', ['$event']) // for window scroll events
  // tslint:disable-next-line:typedef
  onScroll(event: any) {
    const menu = document.getElementById('menu');
    if (menu) {
      if (window. pageYOffset > 0) {
        menu.style.background = '#08071a';
        menu.style.transition = 'all 1s';
      } else {
        menu.style.background = 'transparent';
      }
    }
  }
  acceptCookies(): void {
    localStorage.setItem('acceptedCookie', 'yes');
    this.isCookieVisible = false;
  }
  redirect(): void {
    this.router.navigate(['/TermsConditions']);
  }
  checkCookie(): void {
    const isCookieAccepted = localStorage.getItem('acceptedCookie');
    if (isCookieAccepted === 'yes'){
      this.isCookieVisible = false;
    }
  }
  ngOnInit(): void {
    this.checkLanguage();
    this.checkLocation();
    this.checkCookie();
  }
}
