<div class="event-details-page-container">
  <h1>{{event.title | translate}}</h1>

  <p *ngFor="let detail of details">
    {{detail | translate}}
  </p>

  <div class="img-container">
    <div class="background" *ngFor="let url of urls" >
      <img [src]="url" class="event-img">
    </div>
  </div>
</div>
