<body>
  <div class="desktop">
    <img src="assets/other/404.svg" class="background-image">
    <p id="error-code" class="error-code">404</p>
    <p id="lost-text" class="lost-text">Looks like you got lost in the forest... <br>
      Now you have to choose: go to homepage, where you can be safe, or stay out in the forest..<br>
      in the night.. where wolves can get to you.. Make your choice and make it quick!</p>
    <div class="button-div">
      <table>
        <tr>
          <td>
            <button id="btn-home" class="button-home" (click)="GoToHomepage()">Go to homepage</button>
          </td>
          <td>
            <button id="btn-woods" class="button-woods" (click)="StayInTheWoods()">Stay in the woods</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="tablet">
<!--    <p>tablet</p>-->
    <img src="assets/other/404_tablet.svg" class="background-image">
    <p id="error-code-tablet" class="error-code">404</p>
    <p id="lost-text-tablet" class="lost-text">Looks like you got lost in the forest... <br>
      Now you have to choose: go to homepage, where you can be safe, or stay out in the forest..<br>
      in the night.. where wolves can get to you.. Make your choice and make it quick!</p>
    <div class="button-div">
      <table>
        <tr>
          <td>
            <button id="btn-home-tablet" class="button-home" (click)="GoToHomepage()">Go to homepage</button>
          </td>
          <td>
            <button id="btn-woods-tablet" class="button-woods" (click)="StayInTheWoods()">Stay in the woods</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="mobile">
<!--    <p>mobile</p>-->
    <img src="assets/other/404_mobile.svg" class="background-image">
    <p id="error-code-mobile" class="error-code">404</p>
    <p id="lost-text-mobile" class="lost-text">Looks like you got lost in the forest... <br>
      Now you have to choose: go to homepage, where you can be safe, or stay out in the forest..<br>
      in the night.. where wolves can get to you.. Make your choice and make it quick!</p>
    <div class="button-div">
      <table>
        <tr>
          <td>
            <button id="btn-home-mobile" class="button-home" (click)="GoToHomepage()">Go to homepage</button>
          </td>
          <td>
            <button id="btn-woods-mobile" class="button-woods" (click)="StayInTheWoods()">Stay in the woods</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</body>
