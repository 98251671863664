import {Component, HostListener, Inject, OnInit, QueryList, Renderer2, ViewChildren} from '@angular/core';
import {PaintingCathegoryService} from '../service/paintingCathegory.service';
import {Router} from '@angular/router';
import {EventNumberService} from '../service/eventNumber.service';
import {DOCUMENT} from '@angular/common';
import {UpcomingEventService} from '../service/upcomingEvent.service';
import { TranslateService } from '@ngx-translate/core';
import {LanguageService} from '../service/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  // tslint:disable-next-line:variable-name max-line-length
  constructor(@Inject(DOCUMENT) private document: Document, private _paintingCathegory: PaintingCathegoryService,
              // tslint:disable-next-line:variable-name
              private _eventNumber: EventNumberService, private router: Router, private _upcomingEvents: UpcomingEventService,
              // tslint:disable-next-line:variable-name
              private renderer: Renderer2, private translate: TranslateService, private _language: LanguageService) {
    this.checkLanguage();
    this.GetTodaysDate();
    this.UpCommingEvents();
  }
  windowScrolled = false;
  // tslint:disable-next-line:variable-name
  category_icons_width = 0;
  // tslint:disable-next-line:variable-name
  days_left = 0;
  // tslint:disable-next-line:variable-name
  events_planned = this._upcomingEvents.state;
  today = 0;
  upcommingEvent = {
    day: this._upcomingEvents.day,
    month: this._upcomingEvents.month,
    year: this._upcomingEvents.year,
    location: this._upcomingEvents.location,
    locationHref: this._upcomingEvents.locationHref
  };
  latestPaintings = [
    {
      path: '../assets/gallery/130.jpg',
      title: 'namePicture130',
      description: 'namePicture130',
      category: '1',
      id: '130'
    },
    {
      path: '../assets/gallery/117.jpg',
      title: 'namePicture127',
      description: 'namePicture127',
      category: '2',
      id: '117'
    },
    {
      path: '../assets/gallery/118.jpg',
      title: 'namePicture118',
      description: 'namePicture118',
      category: '1',
      id: '118'
    },
    {
      path: '../assets/gallery/124.jpg',
      title: 'namePicture124',
      description: 'namePicture124',
      category: '1',
      id: '124'
    },
    {
      path: '../assets/gallery/126.jpg',
      title: 'namePicture126',
      description: 'namePicture126',
      category: '2',
      id: '126'
    },
    {
      path: '../assets/gallery/135.jpg',
      title: 'namePicture135',
      description: 'namePicture135',
      category: '1',
      id: '135'
    }
  ];
  slideIndex = 0;

  // tslint:disable-next-line:typedef
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  UpCommingEvents(): void{
    window.scroll(0, 0);
    this.days_left = this._upcomingEvents.getDaysUntiillEvent();
  }
  GetTodaysDate(): void{
    const date = new Date();
    this.today = date.getDate();
  }
  NavigateToGallery(cathegory: number): void{
    window.scroll(0, 0);
    localStorage.setItem('category', String(cathegory));
    this.router.navigate(['Gallery']);
  }
  // tslint:disable-next-line:variable-name
  NavigateToLatestPainting(painting_title: number): void{
    window.scroll(0, 0);
    localStorage.setItem('latestPainting', '92');
    localStorage.setItem('paintingCategory', '1');
    this.router.navigate(['Gallery']);
  }
  NavigateToEvents(eventNumber: number): void{
    window.scroll(0, 0);
    this._eventNumber.update_eventNumber(eventNumber);
    this.router.navigate(['Events']);
  }
  NavigateToNFT(): void{
    window.scroll(0, 0);
    this.router.navigate(['NFT']);
  }
  checkLanguage(): void {
    this._language.current_lang.subscribe(lang => {
      // tslint:disable-next-line:triple-equals
      if (lang == 0 ) {
        this.translate.use('ro');
      } else {
        this.translate.use('en');
      }
    });
  }

  // showSlides(): void {
  //   let i;
  //   const slides = document.getElementsByClassName('mySlides');
  //   const dots = document.getElementsByClassName('dot');
  //   for (i = 0; i < slides.length; i++) {
  //     // @ts-ignore
  //     slides[i].style.display = 'none';
  //   }
  //   this.slideIndex++;
  //   if (this.slideIndex > slides.length) {this.slideIndex = 1; }
  //   for (i = 0; i < dots.length; i++) {
  //     dots[i].className = dots[i].className.replace(' active', '');
  //   }
  //   if (slides[this.slideIndex - 1]){
  //     // @ts-ignore
  //     slides[this.slideIndex - 1].style.display = 'block';
  //     dots[this.slideIndex - 1].className += ' active';
  //   }
  // }
  goToPaintingDetails(category: string, id: string): void {
    this.router.navigate(['Painting/' + category + '/' + id]);
  }
  goToMarket(): void {
    this.router.navigate(['Market']);
  }
  ngOnInit(): void {
   localStorage.setItem('latestPainting', '');
   this.checkLanguage();
   // this.showSlides();
   // window.setInterval(() => {
   //    this.showSlides();
   //  }, 3000);
  }
}
