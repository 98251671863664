<div class="nft-container">
  <div *ngFor="let project of nftProjects">
    <h1 class="title">{{project.name}}</h1>

    <div class="desktop">
      <div *ngIf="project.id === 1" class="hero-table">
        <table>
          <tr>
            <td>
              <h2>
                My name is Andreea. I love painting and I decided to create this NFT collection that has my favourite animals: wolves. <br>
                Every wolf from the pack and every detail is hand-drawn with love. They are all created by hand, with no automatic generation.
              </h2>
              <h1 class="center">Join the pack as soon as possible!</h1>
            </td>
            <td>
              <img src="{{project.heroImageUrl}}">
            </td>
          </tr>
        </table>

        <h1 class="center">
          These wolves are so happy with the pandemic, because nobody violates their territory anymore, and they can be themseves around the forest.
        </h1>

        <table>
          <tr>
            <td>
              <h2>
                Some wolves are casually going out to have a chat with their friends, find a mate, spend some time alone to enjoy the forest (silent and without people) or cook some fine pranks for their friends.
              </h2>
            </td>
            <td>
              <img src="{{project.imgUrl1}}">
            </td>
          </tr>
          <tr>
            <td>
              <img src="{{project.imgUrl2}}">
            </td>
            <td>
              <h2 style="text-align: left">
                Other wolves are attending a VIP GOLD PRIVATE PARTY that has a dress code. <br>
                Can you guess it? It’s: “If you are not gold, wear something that is!”. <br>
                This party is happening at sunrise, so that the sunshine can make the gold accessories or fur shine even brighter. <br>
                NFT with the painting in the background will also be included when purchasing one of these wolves.
              </h2>
            </td>
          </tr>
          <tr>
            <td>
              <h2>
                The last category of wolves have a themed party to imitate famous people like Lady Gaga, Freddie Mercury, Snopp Dogg etc. <br>
                They rented a part of the forest and decorated it with white, gold and black trees.<br>
                This party is scheduled at night, so they can admire themseves as well as the stars. <br>
                Also, what is a wolf without howling at the moon? <br>
                They are free to howl until the sun comes up. <br>
                NFT with the painting in the background will also be included when purchasing one of these wolves.
              </h2>
            </td>
            <td>
              <img src="{{project.imgUrl3}}">
            </td>
          </tr>
        </table>

        <h1 class="center">
          Since they are hand-drawn, I will drop 50 unique wolves at a time.
          I plan to have at least 2 drops a year.<br>
          First drop contains 50 wolves that includes: 5 gold wolves and 10 special wolves. <br>
          The price will get higher with every drop, so join our pack in the early stages! <br>
        </h1>
      </div>
    </div>

    <div class="mobile">
      <div *ngIf="project.id === 1" class="hero-table">
        <img src="{{project.heroImageUrl}}">
        <h2>
          My name is Andreea. I love painting and I decided to create this NFT collection that has my favourite animals: wolves. <br>
          Every wolf from the pack and every detail is hand-drawn with love. They are all created by hand, with no automatic generation.
        </h2>
        <h1 class="center">Join the pack as soon as possible!</h1>
        <h1 class="center">
          These wolves are so happy with the pandemic, because nobody violates their territory anymore, and they can be themseves around the forest.
        </h1>

        <h2>
          Some wolves are casually going out to have a chat with their friends, find a mate, spend some time alone to enjoy the forest (silent and without people) or cook some fine pranks for their friends.
        </h2>
        <img src="{{project.imgUrl1}}">

        <h2>
          Other wolves are attending a VIP GOLD PRIVATE PARTY that has a dress code. <br>
          Can you guess it? It’s: “If you are not gold, wear something that is!”. <br>
          This party is happening at sunrise, so that the sunshine can make the gold accessories or fur shine even brighter. <br>
          NFT with the painting in the background will also be included when purchasing one of these wolves.
        </h2>
        <img src="{{project.imgUrl2}}">

        <h2>
          The last category of wolves have a themed party to imitate famous people like Lady Gaga, Freddie Mercury, Snopp Dogg etc. <br>
          They rented a part of the forest and decorated it with white, gold and black trees.<br>
          This party is scheduled at night, so they can admire themseves as well as the stars. <br>
          Also, what is a wolf without howling at the moon? <br>
          They are free to howl until the sun comes up. <br>
          NFT with the painting in the background will also be included when purchasing one of these wolves.
        </h2>
        <img src="{{project.imgUrl3}}">


        <h2 class="center">
          Since they are hand-drawn, I will drop 50 unique wolves at a time.
          I plan to have at least 2 drops a year.<br>
          First drop contains 50 wolves that includes: 5 gold wolves and 10 special wolves. <br>
          The price will get higher with every drop, so join our pack in the early stages! <br>
        </h2>
      </div>
    </div>

  </div>
</div>
