import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {GalleryComponent} from './gallery/gallery.component';
import {EventsComponent} from './events/events.component';
import {EventDetailsPageComponent} from './event-details-page/event-details-page.component';
import {PaintingDetailsComponent} from './painting-details/painting-details.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AboutComponent} from './about/about.component';
import {GamesComponent} from './games/games.component';
import {NftComponent} from './nft/nft.component';
import {ContactComponent} from './contact/contact.component';
import {MarketComponent} from './market/market.component';
import {LoginComponent} from './login/login.component';
import {OfferComponent} from './offer/offer.component';
import {OfferLcComponent} from './offer-lc/offer-lc.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {PaintingPageComponent} from './painting-page/painting-page.component';
import {RegisterComponent} from './register/register.component';
import {TermsConditionsComponent} from './terms-conditions/terms-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'Gallery',
    component: GalleryComponent
  },
  {
    path: 'Events',
    component: EventsComponent
  },
  {
    path: 'Event/:id',
    component: EventDetailsPageComponent
  },
  {
    path: 'NFT',
    component: NftComponent
  },
  {
    path: 'About',
    component: AboutComponent
  },
  {
    path: 'Games',
    component: GamesComponent
  },
  {
    path: 'KnowMoreAboutMarket',
    component: LoginComponent
  },
  {
    path: 'Market/:id/:artist', // Market/:id/:artist
    component: MarketComponent
  },
  {
    path: 'Market',
    redirectTo: 'Market/0/all', // Market/0/all
    pathMatch: 'full'
  },
  {
    path: 'Login', // Login
    component: LoginComponent
  },
  {
    path: 'Register', // Register
    component: LoginComponent
  },
  // {
  //   path: 'Register',
  //   component: RegisterComponent
  // },
  {
    path: 'Offer',
    component: OfferComponent
  },
  {
    path: 'OfferLupulCreativ',
    component: OfferLcComponent
  },
  {
    path: 'MyAccount',
    component: MyAccountComponent,
  },
  {
    path: 'Contact',
    component: ContactComponent
  },
  {
    path: 'Painting/:categoryId/:productId',
    component: PaintingPageComponent
  },
  {
    path: 'Art/:categoryId/:productId',
    component: PaintingPageComponent
  },
  {
    path: 'TermsConditions',
    component: TermsConditionsComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true , scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

