<div class="about-container">
  <img src="assets/me/Lupul%20A%20(38%20of%20191).jpg" class="image">
  <div class="description">

    <h1>
      {{'aboutH1' | translate}}
    </h1>
    <p>
      {{'aboutP1' | translate}}
      <br>
      <br>
      {{'aboutP2' | translate}}
      <br>
      <br>
      {{'aboutP3' | translate}}
      <br>
      <br>
      {{'aboutP4' | translate}}
      <br>
      <br>
      {{'aboutP5' | translate}}
    </p>

  </div>
</div>
